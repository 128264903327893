export class Character {
  constructor(key, index) {
    this.key = key;
    this.index = index;
  }
}

export class Word {
  constructor(word, characters, index) {
    this.word = word;
    this.characters = characters;
    this.index = index;
  }
}

export class WordsContainer {
  constructor(content) {
    if (!content) {
      this.words = [];
      this.characters = [];
      return;
    }

    const words = content.split(/(\s+)/);

    this.lastIndex = 0;

    this.words = words.reduce((result, word, index) => {
      const characters = this.parseCharacters(word);
      return [...result, new Word(word, characters, index)];
    }, []);

    this.characters = this.words.reduce((result, word) => {
      return [...result, ...word.characters];
    }, []);
  }

  parseCharacters(word) {
    if (!word) {
      return [];
    }

    return word.split("").reduce((result, key) => {
      this.lastIndex++;
      return [...result, new Character(key, this.lastIndex)];
    }, []);
  }
}
