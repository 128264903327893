<div class="content-box">
  {#each words as word, index}
    <span class="word" data-word-index={index}>
      {#each word.characters as { key, index }}
        <span
          class="char"
          class:correct={isCorrect(index)}
          class:incorrect={isIncorrect(index)}
          data-char-index={index}>
          {@html key === ' ' ? '&#8197;' : key}
        </span>
        {#each indicators as indicator (indicator.id)}
          {#if indicator.progress === index}
            <span
              class={`indicator`}
              class:current={($currentPlayer && indicator.id === $currentPlayer.id) || spectateMode}
              style={`color: ${indicator.color}`}>
              <div
                class={`indicator-name`}
                style={`background-color: ${indicator.color}`}>
                {indicator.name}
              </div>
            </span>
          {/if}
        {/each}
      {/each}
    </span>
  {/each}
</div>

{#if nextLetter && !spectateMode}
  <kbd class="next-letter" class:incorrect={nextLetterIncorrect}>
    {#if nextLetterIncorrect}
      Press backspace
    {:else}
      {nextLetter.key === ' ' ? 'Space' : nextLetter.key}
    {/if}

  </kbd>
{/if}

<style src="./ContentBox.scss">

</style>

<script src="./ContentBox.js">

</script>
