<div class="game-view">
  <div class="w-2/12 player-board-container">
    {#each $sortedPlayers as player, index}
      {#if index === 0}
        <div class="player-board board-top">
          <PlayerBox {player} />
        </div>
      {/if}
      {#if index === 2}
        <div class="player-board board-bottom">
          <PlayerBox {player} />
        </div>
      {/if}
    {:else}
      <div class="no-players">
        <i class="fas fa-user-astronaut text-6xl mb-4" />
        <p class="text-xl mb-3 font-bold">Oops...</p>
        <p>There are no players at the moment...</p>
      </div>
    {/each}

  </div>
  <div class="board w-7/12">
    <div class="brand" on:click={navigateToMain}>
      <Brand />
    </div>
    {#if $status !== types.STATUS_IDLE}
      <div class="timer">
        <Timer duration={$timer} />
      </div>
    {/if}

    {#if winner}
      <div
        class="game-result"
        class:winner={winner && winner.id === $currentPlayerId}>
        <p class="winner-1-line">End game,</p>
        <p class="winner-2-line">Winner is {winner.name}!</p>
        <div class="button-group">
          <button on:click={navigateToSpectator} class="popup-button">
            Watch again
          </button>
          <button on:click={navigateToHighscores} class="popup-button">
            Show highscores
          </button>
        </div>

      </div>
    {/if}

    {#if [types.STATUS_PLAYING, types.STATUS_FINISHED].includes($status)}
      <div class="objective" style={winner ? 'opacity: 0.1' : ''}>
        <ContentBox
          body={$body}
          progress={$progress}
          {currentProgress}
          indicators={$sortedPlayers}
          spectateMode={true} />
      </div>
    {:else if $status === types.STATUS_WAITING}
      <i class="fas fa-sync fa-spin mr-3" />
      Waiting for the players to join...
    {:else if $status === types.STATUS_FETCHING}
      <i class="fas fa-sync fa-spin mr-3" />
      Fetching data...
    {:else if $status === types.STATUS_COUNTDOWN}
      The game is about to begin!
    {:else if $status === types.STATUS_TEXT_DRAWN}Last seconds!{/if}
  </div>
  <div class="w-2/12 player-board-container">
    {#each $sortedPlayers as player, index}
      {#if index === 1}
        <div class="player-board board-top">
          <PlayerBox {player} />
        </div>
      {/if}
      {#if index === 3}
        <div class="player-board board-bottom">
          <PlayerBox {player} />
        </div>
      {/if}
    {/each}
  </div>

  <a href="/" use:link class="button">Back to homepage</a>
  <!--<div class="active-games">
    <div>Active games</div>
    <div>
      {#each games as game, index}
        <div>{index}: {game}</div>
      {/each}
    </div>
  </div>-->

</div>

<style src="./Spectator.scss">

</style>

<script src="./Spectator.js">

</script>
