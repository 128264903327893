import io from "socket.io-client";
import * as types from "helpers/types.js";

const SERVER_URL = process.env.SERVER_URL;

export class GameClient {
  constructor() {
    if (!!GameClient.instance) {
      return GameClient.instance;
    }

    this.onGameJoined = null;
    this.onPlayerJoined = null;
    this.onStartCountdown = null;
    this.onStartGame = null;
    this.onTextDrawn = null;
    this.onStatusChange = null;
    this.onPlayerFinished = null;
    this.onHighscoresChange = null;
    this.onCountdownTick = null;

    this.socket = io(SERVER_URL);
    this.socket.on("message", response => this.onMessage(response));
    this.socket.on("connect", () => (this.playerId = this.socket.id));

    GameClient.instance = this;

    return this;
  }

  joinGame(name) {
    const payload = {
      type: types.CLIENT_JOIN_GAME,
      payload: {
        name,
      },
    };
    this.socket.send(payload);
  }

  joinPractice(name) {
    const payload = {
      type: types.CLIENT_JOIN_PRACTICE,
      payload: {
        name,
      },
    };
    this.socket.send(payload);
  }

  updatePlayerStatus({ gameId, playerId, currentCharacter }) {
    const payload = {
      type: types.CLIENT_PLAYER_STATUS,
      payload: {
        gameId,
        playerId,
        currentCharacter,
      },
    };
    this.socket.send(payload);
  }

  getHighscores() {
    const payload = {
      type: types.CLIENT_HIGHSCORES,
    };
    this.socket.send(payload);
  }

  spectate() {
    const payload = {
      type: types.CLIENT_SPECTATE,
    };
    this.socket.send(payload);
  }

  onMessage(response) {
    const { type, payload } = response;

    switch (type) {
      case types.SERVER_GAME_JOINED:
        this.onGameJoined && this.onGameJoined(payload);
        break;
      case types.SERVER_PLAYER_JOINED:
        this.onPlayerJoined && this.onPlayerJoined(payload);
        break;
      case types.SERVER_START_COUNTDOWN:
        this.onStartCountdown && this.onStartCountdown(payload);
        break;
      case types.SERVER_START_GAME:
        this.onStartGame && this.onStartGame(payload);
        break;
      case types.SERVER_TEXT_DRAWN:
        this.onTextDrawn && this.onTextDrawn(payload);
        break;
      case types.SERVER_STATUS:
        this.onStatusChange && this.onStatusChange(payload);
        break;
      case types.SERVER_FINISHED:
        this.onPlayerFinished && this.onPlayerFinished(payload);
        break;
      case types.SERVER_HIGHSCORES:
        this.onHighscoresChange && this.onHighscoresChange(payload);
        break;
      case types.SERVER_TICK:
        this.onCountdownTick && this.onCountdownTick(payload);
        break;
    }
  }
}
