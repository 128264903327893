export const KEYBOARD_ALLOWED_KEY = "keyboard/allowed-key";
export const KEYBOARD_DELETE_KEY = "keyboard/delete-key";
export const KEYBOARD_FUNCTION_KEY = "keyboard/function-key";
export const KEYBOARD_OTHER_KEY = "keyboard/other-key";

export const STATUS_IDLE = "status/idle";
export const STATUS_WAITING = "status/waiting";
export const STATUS_COUNTDOWN = "status/countdown";
export const STATUS_FETCHING = "status/fetching";
export const STATUS_PLAYING = "status/playing";
export const STATUS_FINISHED = "status/finished";
export const STATUS_TEXT_DRAWN = "status/text-drawn";

export const CLIENT_JOIN_GAME = "join-game";
export const CLIENT_JOIN_PRACTICE = "join-practice";
export const CLIENT_PLAYER_STATUS = "player-status";
export const CLIENT_HIGHSCORES = "highscores";
export const CLIENT_SPECTATE = "spectate";

export const SERVER_GAME_JOINED = "joined-game";
export const SERVER_PLAYER_JOINED = "player-joined";
export const SERVER_START_COUNTDOWN = "start-countdown";
export const SERVER_START_GAME = "start-game";
export const SERVER_TEXT_DRAWN = "text-drawn";
export const SERVER_STATUS = "game-status";
export const SERVER_FINISHED = "player-finished";
export const SERVER_HIGHSCORES = "highscores";
export const SERVER_TICK = "countdown-tick"
