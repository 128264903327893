import { writable, derived, readable } from "svelte/store";

import { createTimer } from "store/game/timer.js";
import { createPlayers } from "store/game/players.js";
import { createProgress } from "store/game/progress.js";

import * as types from "helpers/types.js";

export const status = writable(types.STATUS_IDLE);
export const timer = createTimer();

export const progress = createProgress();
export const body = writable("");
export const players = createPlayers();
export const currentPlayerId = writable("");

export const sortedPlayers = derived(players, $players =>
  Object.values($players).sort(
    (a, b) => b.progress - a.progress,
  ),
);

export const currentPlayer = derived(
  [players, currentPlayerId],
  ([$players, $currentPlayerId]) => $players[$currentPlayerId],
);
