<div class="circle" style={componentStyles}>
  <span>
    {#each playerNameParts as namePartial}
      {#if playerNameParts.length === 1}
        {player.name
          .trim()
          .substr(0, 3)
          .toUpperCase()}
      {:else}{namePartial.trim()[0].toUpperCase()}{/if}
    {/each}
  </span>
</div>

<style src="./InitialCircle.scss">

</style>

<script src="./InitialCircle.js">

</script>
