export function parseTimer(duration) {
  const minutes = parseInt(duration / 60, 10);
  const seconds = parseInt(duration % 60, 10);

  const result = {
    minutes: minutes < 10 ? "0" + minutes : minutes,
    seconds: seconds < 10 ? "0" + seconds : seconds,
  };

  return `${result.minutes}:${result.seconds}`;
}
