import { writable } from "svelte/store";

export function createTimer() {
  const { subscribe, set, update } = writable(0);
  let timer;

  return {
    subscribe,
    start: () => {
      timer = setInterval(() => update(n => {
        if (n <= 0) {
          clearInterval(timer);
          return 0;
        }
        return n - 1
      }), 1000)
    },
    stop: () => clearInterval(timer),
    set: (value) => set(value),
    reset: () => set(0),
  };
}
