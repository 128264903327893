<div class="container">
  <div class="ranking-index w-1/12">{index}.</div>
  <div class="leaderboard-item w-11/12">

    <div class="w-2/12">
      <InitialCircle {player} zoom="0.8" />
    </div>
    <div class="w-6/12 player-name">{player.name}</div>
    <div class="w-4/12 player-score">{player.score}s</div>
  </div>

</div>

<style src="./LeaderboardItem.scss">

</style>

<script src="./LeaderboardItem.js">

</script>
