import { writable } from "svelte/store";
import { Player } from "helpers/player.js";

export function createPlayers() {
  const { subscribe, set, update } = writable({});

  return {
    subscribe,
    add: player =>
      update(players => ({ ...players, [player.id]: new Player(player) })),
    remove: id => update(players => delete players[id]),
    setList: list =>
      set(
        list.reduce((result, player, index) => {
          result[player.id] = new Player({ ...player, number: index });
          return result;
        }, {}),
      ),
    updateProgress: (id, progress) =>
      update(players => ({
        ...players,
        [id]: {
          ...players[id],
          progress,
        },
      })),
    clear: () => set({}),
  };
}
