<div class="leaderboard-view">
  <div class="game-title-container">
    <div class="game-title">
      <p>TypeRunner.js</p>
    </div>
    <hr class="separate-line" />
    <div class="game-subtitle">HIGHSCORES</div>
  </div>

  <div class="leaderboard-container">
    {#each highscorePlayers as player, index}
      <LeaderboardItem {player} index={index + 1} />
    {/each}
  </div>

  <a href="/" use:link class="button">Back to homepage</a>
</div>

<style src="./Leaderboard.scss">

</style>

<script src="./Leaderboard.js">

</script>
