import * as types from "helpers/types.js";

export function getKeyCodeType(e) {
  if (metaKey(e)) {
    return types.KEYBOARD_FUNCTION_KEY;
  } else if (allowedKey(e)) {
    return types.KEYBOARD_ALLOWED_KEY;
  } else if (deleteKey(e)) {
    return types.KEYBOARD_DELETE_KEY;
  } else {
    return types.KEYBOARD_OTHER_KEY;
  }
}

export function allowedKey({ keyCode, metaKey, ctrlKey, altKey, shiftKey }) {
  const valid =
    (keyCode > 47 && keyCode < 58) || // number keys
    keyCode == 32 || // spacebar
    (keyCode > 64 && keyCode < 91) || // letter keys
    (keyCode > 95 && keyCode < 112) || // numpad keys
    (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
    (keyCode > 218 && keyCode < 223); // [\]' (in order)

  return valid;
}

export function deleteKey({ keyCode }) {
  const valid = keyCode == 8; // backspace

  return valid;
}

export function metaKey({ metaKey, ctrlKey, altKey, shiftKey }) {
  const valid = metaKey || ctrlKey;

  return valid;
}
