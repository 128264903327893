const arr = ["#77A0E1", "#FFA5BC", "#1C236D", "#EF4A57"];

export class Player {
  constructor(player) {
    this.id = player.id;
    this.name = player.name;
    this.color = arr[player.number];
    this.progress = player.currentCharacter;
  }
}
