<div class="home-view">
  <div class="game-title-container">
    <div class="game-title">
      <p>TypeRunner.js</p>
    </div>
  </div>
  <div class="game-login-container">
    <div class="game-login">
      <Brand />
      <form
        action="#"
        class="w-full max-w-sm mx-auto mt-6 pt-3"
        on:submit={handleSubmit}
        autocomplete="off">
        <label class="label pl-4 player-name text-base" for="playerName">
          Player name
        </label>
        <input
          id="playerName"
          type="text"
          class="player-name-input input w-full max-w-sm mb-3"
          placeholder="Enter your name"
          bind:value={name}
          disabled={loading}
          maxlength="8"
          autofocus
          pattern="[A-Za-z0-9]+|[A-Za-z0-9]+\s[A-Za-z0-9]+"
          title="Player name should consist of a-z, A-Z, 0-9 characters" />
        <div class="button-container pt-3">
          <button
            type="submit"
            class="button game-button"
            disabled={!name || loading}>
            Start the game
          </button>
        </div>
        <div class="button-container pt-3">
          <button
            on:click={practice}
            class="button practice-button"
            disabled={!name || loading}>
            Practice mode
          </button>
        </div>
      </form>

      <!--<div class="footer">&copy; The Software house | v0.3</div>-->
    </div>
  </div>
  <div class="game-footer-container">
    <div class="footer-text">
      <a class="footer-text-link" target="_blank" href="https://tsh.io/blog/svelte-app/">
        We've developed TypeRunner in Svelte. See how
      </a>
    </div>
    <div class="brand-site">
      <a target="_blank" href="https://tsh.io/">tsh.io</a>
    </div>
  </div>

</div>

<style src="./Home.scss">

</style>

<script src="./Home.js">

</script>
