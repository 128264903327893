<div class="timer" class:no-display={$status === types.STATUS_WAITING}>
  <i class="far fa-clock" />
  {timer}
</div>

<style src="./Timer.scss">

</style>

<script src="./Timer.js">

</script>
