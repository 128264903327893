<div class="player-box">
  <div class="leaderboard-progress">
    <ProgressBar progress={player.progress} {total} background={player.color} />
  </div>
  <div class="player-details">
    <div class="w-4/12 left">
      <InitialCircle {player} />
    </div>
    <div class="w-8/12 right">
      <div class="player-name">{player.name}</div>
      <div class="player-result">
        <span class="progress">{wpm}</span>
        <span class="unit">wpm</span>
      </div>
    </div>
  </div>
</div>

<style src="./PlayerBox.scss">

</style>

<script src="./PlayerBox.js">

</script>
