<div class="progress">
  <div class="bar" style={width} />
</div>

<style src="./ProgressBar.scss">

</style>

<script src="./ProgressBar.js">

</script>
