<svelte:window
  on:keydown={handleKeyDown}
  on:focus={() => (hasFocus = true)}
  on:blur={() => (hasFocus = false)} />

<div class="game-view">
  {#if !hasFocus}
    <div class="alert">
      Lost focus! Click on the window to get the focus back!
    </div>
  {/if}

  {#if $progress < currentProgress}
    <div class="alert">You've made a mistake. Fix the typo!</div>
  {/if}
  <div class="w-2/12 player-board-container">
    {#each $sortedPlayers as player, index}
      {#if index === 0}
        <div class="player-board board-top">
          <PlayerBox {player} />
        </div>
      {/if}
      {#if index === 2}
        <div class="player-board board-bottom">
          <PlayerBox {player} />
        </div>
      {/if}
    {:else}
      <div class="no-players">
        <i class="fas fa-user-astronaut text-6xl mb-4" />
        <p class="text-xl mb-3 font-bold">Oops...</p>
        <p>There are no players at the moment...</p>
      </div>
    {/each}

  </div>
  <div class="board w-7/12">
    <div class="brand" on:click={navigateToMain}>
      <Brand />
    </div>
    {#if $status !== types.STATUS_IDLE}
      <div class="timer">
        <Timer duration={$timer} />
      </div>
    {/if}

    {#if winner}
      <div
        class="game-result"
        class:winner={winner && winner.id === $currentPlayerId}>
        <div>
          {#if winner.id === $currentPlayerId}
            <p class="winner-1-line">Congratulations!</p>
            <p class="winner-2-line">You win!</p>
          {:else}
            <p class="winner-1-line">Sorry buddy,</p>
            <p class="winner-2-line">
              you lose. The winner is {winner.name}.
            </p>
          {/if}
          <p class="winner-2-line">
            With score: {#if score}{score}{/if}
          </p>
        </div>
        <div class="button-group">
          <button on:click={navigateToMain} class="popup-button">
            Play again
          </button>
          {#if gameType !== gameTypes.PRACTICE}
            <button on:click={navigateToHighscores} class="popup-button">
              Show highscores
            </button>
          {/if}
        </div>
      </div>
    {/if}

    {#if [types.STATUS_PLAYING, types.STATUS_FINISHED].includes($status)}
      <div class="objective" style={winner ? 'opacity: 0.1' : ''}>
        <ContentBox
          body={$body}
          progress={$progress}
          {currentProgress}
          indicators={$sortedPlayers} />
      </div>
    {:else if $status === types.STATUS_WAITING}
      <i class="fas fa-sync fa-spin mr-3" />
      Waiting for other players to join...
    {:else if $status === types.STATUS_FETCHING}
      <i class="fas fa-sync fa-spin mr-3" />
      Fetching data...
    {:else if $status === types.STATUS_COUNTDOWN}
      Get ready! The game is about to begin!
    {:else if $status === types.STATUS_TEXT_DRAWN}Get ready, last seconds!{/if}

  </div>
  <div class="w-2/12 player-board-container">
    {#each $sortedPlayers as player, index}
      {#if index === 1}
        <div class="player-board board-top">
          <PlayerBox {player} />
        </div>
      {/if}
      {#if index === 3}
        <div class="player-board board-bottom">
          <PlayerBox {player} />
        </div>
      {/if}
    {/each}
  </div>
</div>

<style src="./Game.scss">

</style>

<script src="./Game.js">

</script>
