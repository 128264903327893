<svelte:head />

<main>
  <Router {url}>
    <Route path="/" component={Redirect}/>
    <Route path="/typerunner" component={Home} />
    <Route path="/typerunner/game/:id" component={Game} />
    <Route path="/typerunner/leaderboard" component={Leaderboard} />
    <Route path="/typerunner/spectate" component={Leaderboard} />
    <Route path="/typerunner/spectate/:id" component={Spectator} />
  </Router>
</main>

<style global src="../../assets/styles/global.scss">

</style>

<script src="./Main.js">

</script>
